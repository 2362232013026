import clsx from "clsx";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../hooks/hook";
import useInView from "../hooks/useInView";
import { hostBE } from "../types/host";

type propSystem = {
  value: {
    titleVi: string;
    titleEn: string;
    descriptionVi: string;
    descriptionEn: string;
    image: string;
  };
  reverse: boolean;
};
const SystemIntro = ({ value, reverse }: propSystem) => {
  const { isInView, ref } = useInView();
  const translate = useAppSelector((state) => state.translateSlice);
  return (
    <div
      className="w-1920:pl-[300px] w-1920:pr-[319px] xl:px-[100px] m992:px-[60px] md:px-[130px] sm-480:px-[66px] px-[30px] pb-[10px] md:pb-[40px] xl:pb-[60px]"
    >
      <div
        ref={ref}
        className={clsx(
          "lssm:text-px20 md:text-[40px] xl:text-[48px] xl:py-[60px] sm-390:py-[50px] py-5 font-bold text-center uppercase",
          { "animate__animated animate__fadeInUp": isInView }
        )}
      >
        {translate.isEnglish ? value.titleVi : value.titleEn}
      </div>
      <div
        className={clsx(
          "flex w-1920:items-center m992:justify-between m992:flex-row flex-col",
          reverse && "m992:flex-row-reverse"
        )}
      >
        <div className= {clsx("m992:w-2/5 w-full m992:mb-0 mb-10")}>
          <img
            className={clsx("w-full object-contain", {
              "animate__animated animate__fadeInLeft rounded-xl object-contain": isInView,
            })}
            src={value.image}
            alt="anh"
          />
        </div>
        <div
          className={clsx(
            "m992:w-2/4 w-full xl:text-base text-sm text-justify flex items-center",
            { "animate__animated animate__fadeInRight": isInView }
          )}
        >
          <p>
            {translate.isEnglish ? value.descriptionVi : value.descriptionEn}
          </p>
        </div>
      </div>
    </div>
  );
};

function SystemManager() {
  const [t] = useTranslation();

  const [dataManager] = useState([
    {
      image: `${hostBE}/fe/logo_anfico1.5.png`,
      titleVi: "Cam kết của chúng tôi",
      titleEn: "Our commitment",
      descriptionVi:
        "ANFICO cam kết cung cấp những sản phẩm đẳng cấp châu  Âu, chất lượng hàng đầu đến cho khách hàng. Luôn đề cao quy trình kiểm soát chất lượng, thường xuyên đầu tư vào máy móc thiết bị, tiếp tục hoàn thiện quy trình sản xuất, nâng cao kỹ năng và tay nghề của cán bộ công nhân viên để không ngừng thúc đẩy chất lượng dịch vụ và sản phẩm vươn lên những tầm cao mới.",
      descriptionEn:
        "ANFICO is dedicated to provide customers with European-class, high-quality products. Maintain the quality control process at all times, invest in machinery and equipment on a regular basis, continue to improve the production process, the skills and abilities of employees to constantly promote service quality.",
    },

    {
      image: `${hostBE}/fe/systemImage2.png`,
      titleVi: "VĂN HÓA DOANH NGHIỆP",
      titleEn: "CORPORATE CULTURE",
      descriptionVi:
        "ANFICO chủ trương theo đuổi sự hoàn mỹ, từ lãnh đạo đến người công nhân thấm nhuần tư tưởng cầu toàn trong từng công đoạn, chú trọng từng chi tiết nhỏ nhất để đạt được sản phẩm chất lượng tốt nhất cho khách hàng. Không ngừng học hỏi công nghệ tiến bộ mới nhất trên thế giới kết hợp việc sử dụng thế mạnh về kinh nghiệm kết hợp với sáng tạo, ANFICO thường xuyên chế tạo ra các máy móc, công cụ gia công chuyên dụng để có thể giảm sức lao động, nâng cao năng suất cho người công nhân, tạo ra được sản phẩm nhanh hơn, tốt hơn so với đối thủ cạnh tranh. Trọng tâm của chúng tôi là coi mỗi công trình, mỗi sản phẩm như một đứa con tinh thần, vì thế việc dành thời gian, tâm huyết và sức lực cho nó chính là niềm say mê, mỗi sản phẩm chúng tôi tạo ra kèm với tự hào của bản thân.",
      descriptionEn:
        "ANFICO promotes the pursuit of perfection, from leaders to workers who are imbued with perfectionism at every stage, paying attention to the smallest details to achieve the highest quality products for customers. Constantly learning the most advanced technology in the world, combining experience and creativity, ANFICO regularly manufactures specialized processing machines and tools to reduce labor force and improve worker productivity; create products faster and better than competitors. Our focus is on treating each project and product as if it were our brainchild, thus, devoting time, enthusiasm, and energy to it as a passion, and each product is created with pride.",
    },

    {
      image: `${hostBE}/fe/systemImage3.png`,
      titleVi: "Đội ngũ nhân viên",
      titleEn: "staff",
      descriptionVi:
        "Với tinh thần coi mỗi cán bộ công nhân viên là một thành viên trong gia đình, ANFICO tạo điều kiện tốt nhất để từng thành viên lao động và sinh hoạt trong công ty được tâm lý như làm việc cho gia đình mình. Mọi thành viên đều cố gắng góp phần xây dựng ANFICO phát triển ngày một vững mạnh.",
      descriptionEn:
        "ANFICO creates the best conditions for each member working and living in the company to feel like they are working for their family, with the spirit of considering each employee as a member of the family. Every member works hard to help ANFICO grow stronger.",
    },
  ]);

  return (
    <div className="w-full h-auto">
      <div className="w-full bg-text-primary lssm:mb-[10px] md:mb-[50px]">
        <div className="h-auto w-full flex items-center relative">
          <img src={`${hostBE}/fe/Maskgroup.png`} alt="ảnh" />
          <div className="absolute top-[50%]  lssm:right-4 sm-390:right-[20px]  m992:right-[73px]  sm-480:right[90px]   sc2200:right-[20vw] translate-y-[-50%]">
            <h5 className="text-px14 sm-390:text-[18px]  md:text-[32px] xl:text-[66px] 2xl:text-[100px] w-1920:text-[110px] uppercase font-bold  text-white animate__animated animate__bounce">
              {t("about.manager_system.title")}
            </h5>
          </div>
        </div>
      </div>
      {dataManager.map((value, index) => {
        return (
          <SystemIntro
            key={index}
            reverse={index % 2 === 0 ? false : true}
            value={value}
          />
        );
      })}
    </div>
  );
}

export default SystemManager;
