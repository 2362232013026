import clsx from "clsx";
import { isBuffer } from "lodash";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import { BsXLg } from "react-icons/bs";
import HTMLFlipBook from "react-pageflip";
import { useAppDispatch, useAppSelector } from "../../hooks/hook";
import { hideModal } from "../../reducers/modal";
import {hostBE} from "../../types/host";

type Props = {
  children: any
  index: number
}

const PageCover = React.forwardRef(({children, index}:Props, ref:any) => {
  return (
    <div className="w-full h-full"  data-density="hard" ref={ref}>
        <div>
          {children}
        </div>
     
    </div>
  );
});

const Page = React.forwardRef(({children, index}:Props, ref:any) => {
  return (
    <div className="w-full h-full  flex justify-center items-center"     ref={ref}>
        
        <div>
          {children}
        </div>
    </div>
  );
});


function BourchureIndex() {
  const dispatch = useAppDispatch();
  const [sizeBouchure, setSizeBouchure] = useState({
    width: 550,
    height: 850,
  });
  const [pages, setPage] = useState({
    page: 0,
    totalPage: 0,
  });
  const [isCover, setIsCover] = useState(0);
  const [pageCover, setPageCover] = useState(0);
  const translate = useAppSelector(state => state.translateSlice)


  const book = useRef<any>();
  const onPage = (e: any) => {

    setPage({
      ...pages,
      page: e.data,
    });
  };

  const nextPage = () => {
    if (book.current) {
     
      book.current?.pageFlip()?.flipNext();
      const countPages = book.current.pageFlip().getPageCount() - 1;
      setPage({
        ...pages,
        totalPage: countPages,
      });
    }
  };
  const prevPage = () => {
    if (book.current) {

      book.current?.pageFlip()?.flipPrev();
      const countPages = book.current.pageFlip().getPageCount() - 1;
      setPage({
        ...pages,
        totalPage: countPages,
      });
    }
  };
  const closeModal = () => {
    dispatch(hideModal());
  };

  useEffect(() => {
    function handleResize() {
      const windowClientWidth = window.innerWidth;
      const percent = windowClientWidth >= 452 ? windowClientWidth*0.7/2  : windowClientWidth*0.6/2
      const heightBrouchure = percent + 250;
      setSizeBouchure({
        width: percent,
        height: heightBrouchure,
      });
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [setSizeBouchure]);

  const renderPage = useCallback(() => {
    let html:any[] = [];
    for(let i = 0; i < 30; i++) {

      html.push(<Page  key={i} index={i + 2}>
         <img className="w-full min-h-full min-w-full  max-w-[500px] h-full max-h-[850px]" alt="" src={`${hostBE}/fe/${translate.isEnglish ? "brochure" : "brochureEn"}/${i + 2}.jpg`} />  
      </Page>)
    }

    return html;
  }, [])

  useEffect(() => {
    window.addEventListener("keydown", (e:KeyboardEvent) => {
        if(e.key === "ArrowRight") {
          nextPage()
        }else if(e.key === "ArrowLeft") {
          prevPage();
        }     
    })

    return ()=> {
      window.removeEventListener("keydown", (e)=> {
        closeModal()
      })
    }
  }, [])

  const handleCoverPage = (e:any) => {
 
    if(e.data === "user_fold" || e.data === "flipping") {
      let page:any;
      
      page = e.object.pages.currentPageIndex;

      if((pageCover === 3 && page === 1)) {
         setIsCover(0)
      }else if(pageCover === 27 && page === 29) {
        setIsCover(2)
      }else {
        setIsCover(1)
      }
      setPageCover(page)
    }
  }
  // console.log(isCover)

  return (
    <div className="w-screen overflow-hidden h-screen relative">
<div className={clsx("w-fit h-fit  cursor-pointer absolute  brochure sc>768:top-[40%]", {"translate-x-[-75%] transition-transform duration-[500] ease-in":isCover===0, "translate-x-[-50%]   transition-transform duration-[500] ease-in":isCover===1, "translate-x-[-25%]   transition-transform duration-[500] ease-in":isCover===2})}>
        <HTMLFlipBook
          drawShadow={false}
          onFlip={onPage}
          flippingTime={1000}
          autoSize={true}
          showCover={true}
          ref={book}
          startPage={0}
          startZIndex={20}
          usePortrait={false}
          maxShadowOpacity={0.5}
          mobileScrollSupport={false}
          width={sizeBouchure.width}
          height={sizeBouchure.height}
          onChangeState={handleCoverPage}
          size="fixed"
          clickEventForward={true} 
          useMouseEvents={true}
          swipeDistance={30}
          showPageCorners={false}
          disableFlipByClick={false}
          className=""
          style={{}}
          minHeight={0}
          minWidth={0}
          maxHeight={850}
          maxWidth={500}
        
      
        >
          <PageCover  index={1}  >
              <img className="w-full min-h-full min-w-full   max-w-[500px] h-full  max-h-[850px]" alt="" src={`${hostBE}/fe/${translate.isEnglish ? "brochure" : "brochureEn"}/1.jpg`} />
          </PageCover>
          {
            renderPage()
          }
          <PageCover index={32} >
              <img className="w-full min-h-full min-w-full  max-w-[500px] h-full  max-h-[850px]" alt="" src={`${hostBE}/fe/${translate.isEnglish ? "brochure" : "brochureEn"}/32.jpg`} />
          </PageCover>

        </HTMLFlipBook>

</div>
 

      <div
        className={clsx(
          "absolute top-[50%]  left-5 p-1 text-black bg-bg-button-bourcure flex items-center"
        )}
      >
        <button onClick={prevPage}>
          <AiOutlineLeft className="text-4xl" />
        </button>
      </div>
      <div
        className={clsx(
          "absolute top-[50%]   text-black right-5 p-1 bg-bg-button-bourcure flex items-center"
        )}
      >
        <button onClick={nextPage}>
          <AiOutlineRight className="text-4xl" />
        </button>
      </div>

      <div className="absolute top-0 right-3 text-black p-1 bg-bg-button-bourcure flex items-center cursor-pointer">
        <BsXLg onClick={closeModal} />
      </div>
    </div>
  );
}

export default BourchureIndex;
