type Props = {
    width: number | string;
    height: number | string;
  };
  
  export default function MapPlace({ width, height }: Props) {
    return (
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3735.3282300912856!2d105.9172534154794!3d20.574649208499892!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3135cf2d4a579e01%3A0x586d43122c8bbe79!2zTmjDoCBtw6F5IEFORklDTw!5e0!3m2!1sen!2s!4v1660900575071!5m2!1sen!2s" width={width} height={height}></iframe>
    );
  }
  