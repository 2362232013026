type Props = {
  width: number | string;
  height: number | string;
};

export default function Map({ width, height }: Props) {
  return (
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14895.584304075885!2d105.7446818585225!3d21.036843866163967!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x313454c754b94a3d%3A0xf609f4c4026eee8e!2zQ8O0bmcgVHkgVE5ISCBUaGnhur90IELhu4sgQ8O0bmcgTmdoaeG7h3AgVGjhu7FjIFBo4bqpbSBBbiBWxrDhu6NuZw!5e0!3m2!1sen!2s!4v1658371160124!5m2!1sen!2s"
      width={width}
      height={height}
    ></iframe>
  );
}
